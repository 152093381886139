import Vue from 'vue'
import moment from 'moment'
// import wp from '../modules/clients/wp'
import bannerData from '@/data/banner.json'
import optionsData from '@/data/options.json';

const d = {
  'TEFLORG': "£",
  'TEFLUS': "$",
  'IRELAND': "€",
  'CANADA': "CA$",
  'AUSTRALIA': "A$",
  'SOUTHAFRICA': "R",
}
const e = {
  'TEFLORG': "gb",
  'TEFLUS': "us",
  'EU': "eu",
  'IRELAND': "ie",
  'CANADA': "ca",
  'AUSTRALIA': "au",
  'SOUTHAFRICA': "za",
}
const f = {
  'TEFLORG': "GBP",
  'TEFLUS': "USD",
  'IRELAND': "EUR",
  'CANADA': "CAD",
  'AUSTRALIA': "AUD",
  'SOUTHAFRICA': "ZAR"
}
const g = {
  "GBP": 'TEFLORG',
  "USD": 'TEFLUS',
  'EUR': "IRELAND",
  'CAD': "CANADA",
  'AUD': "AUSTRALIA",
  'ZAR': "SOUTHAFRICA"
}
const m = {
  "asiamodal": 'GuidesTeflAsiaGuide',
  "worldmodal": 'GuidesTeflWorldGuide',
  'europemodal': "GuidesTeflEuropeGuide",
  'latinmodal': "GuidesTeflLatinAmericaGuide",
  'middleeastmodal': "GuidesTeflMiddleEastGuide",
  'onlinemodal': "GuidesTeflOnlineGuide",
  'brochuremodal': "FormsTeflBrochure",
  'podcast': "FormsPodcastNewsletter",
  'contactpodcast': "FormsContactPodcast"
}
const extName = {
  153: 'one week',
  154: 'one week',
  155: "one month",
  156: "one month",
  157: "two month",
  158: "two month",
  159: "three month",
  160: "three month",
}
export default ({
  store,app
}) => {
  // PRICE DATA
  Vue.filter('priceData', (product) => {
    /*
    if (postID === undefined){
      console.log('No postID - Looking up pageFromProduct...')
      // FIND A WAY TO GET THE POST ID FROM PRODUCT ID
      const data = wp(
        `query MyQuery($productId: String, $limit: Int, $region: String) {
          pages(
            where: {metaQuery: {metaArray: [{key: "product_id", value: $productId, compare: EQUAL_TO}, {key: "region", value: $region, compare: EQUAL_TO}], relation: AND}}
            first: $limit
          ) {
            nodes {
              pageId
              region
              productId
            }
          }
        }
        `,
        {
          productId: String(product.id),
          limit: 1,
          region: store.state.geolocation.region === 'en' ? 'uk' : 'us',
        }
      )
      console.log('returned data: ',data)
      const pageFromProduct = data.pages.nodes.pageId
      console.log('pageFromProduct: ',pageFromProduct)
      postID = String(pageFromProduct)
    } else {
      postID = String(postID)
    }
    */

    // console.log('BUILDING PRICEDATA...',product)

    // DEFAULTS
    let priceText = ''
    let cost = 0
    const banner = bannerData
    let saleType = 'none'
    let priceDiscounted = 0
    // eslint-disable-next-line prefer-const
    let pricesDiscounted = []
    let offerCode = ''
    let offerEnds = ''
    let offerAmount = ''
    let discountType = ''
    let priceData = {}
    const onSale = banner.on_sale.split(',');
    const onSaleBF = banner.on_sale_bf.split(',');
    // We can just set this to be the product ID now the banner data is updated
    const postID = String(product.id)
    // Sometimes this is prices sometimes just price?
    product.prices = product.prices || product.price
   
    // CONSTS
    const defaultCurrency = store.state.geolocation.detectedCurrency || app.$cookies.get('tefl_geolocation_currency') || 'GBP'
    const defaultPriceLabel = g[defaultCurrency]
    // const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : defaultPriceLabel
    const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : store.state.cart.defaultPriceLabel
    const price = product.prices.filter(price => price.label === l)
    // Add default in case regional pricing isn't set up
    const h = product.prices.filter(price => price.label === 'DEFAULT')
    const currency = f[l]
    // WE NEED TO SET THIS UP
    // const region = store.state.geolocation.region
    const region = store.state.user.userRegion === 'default' ? store.state.geolocation.detectedRegion : store.state.user.userRegion
    
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    });
    

    // Get the original cost of product in correct currency

    
    if (price[0]) {
      cost = parseFloat(price[0].cost)
      priceText = formatter.format(cost)
    } else {
      cost = parseFloat(h[0].cost)
      priceText = formatter.format(cost)
    }
    

    // Let's get the data we need...

    // BUNDLES
    if (product.type === 'bundle') {

      console.log('bundleProduct:',banner.bundle_amount)

      // sale type
      saleType = 'BUNDLE'
      discountType = banner.bundle_type

      // Get code and date depending on region
      offerCode = banner.bundle_code.toUpperCase()
      // Hard code end date because the code will always run
      offerEnds = "2099-12-12"


      // Flat discount
      if(discountType === 'flatdiscount') {
        for(const price of product.prices){
          const fullCost = Math.round(price.cost * 100) / 100
          const priceDiscounted = Math.round((fullCost - banner.bundle_amount) * 100) / 100
          const discountAmount = priceDiscounted === fullCost ? 0 : fullCost - priceDiscounted
          const discountPercentage = priceDiscounted === fullCost ? 0 : Math.round(( (fullCost - priceDiscounted)/fullCost ) * 100)
          const priceObject = {
            id: price.id,
            label: price.label,
            fullCost,
            cost: priceDiscounted,
            discountAmount,
            discountPercentage,
            code:offerCode,
            ends:offerEnds,
            // discountAmount: fullCost - priceDiscounted,
            // discountPercentage: ( priceDiscounted / fullCost ) * 100
            // discountPercentage: Math.round(( (fullCost - priceDiscounted)/fullCost ) * 100),
          }
          pricesDiscounted.push(priceObject)
        }
      }

      // Percentage discount
      if(discountType === 'percentage') {
        for(const price of product.prices){
          const fullCost = Math.round(price.cost * 100) / 100
          const priceDiscounted = Math.round((fullCost * ( ( 100 - banner.bundle_amount ) / 100 )) * 100) / 100
          const discountAmount = priceDiscounted === fullCost ? 0 : fullCost - priceDiscounted
          const discountPercentage = banner.bundle_amount
          const priceObject = {
            id: price.id,
            label: price.label,
            fullCost,
            cost: priceDiscounted,
            discountAmount,
            discountPercentage,
            code:offerCode,
            ends:offerEnds,
          // discountAmount: fullCost - priceDiscounted,
            // discountPercentage: ( priceDiscounted / fullCost ) * 100
          }
          pricesDiscounted.push(priceObject)
        }
      }

      // console.log('pricesDiscounted: ',pricesDiscounted)

    }

    // Check if this course is on sale (BF)
    else if (
      onSaleBF.includes(postID) ||
      onSaleBF.includes(postID+'b') || // BUNDLES
      onSaleBF.includes(postID+'v') // VIRTUAL
      ) {

     // console.log('Product on sale (BF): ',postID,banner.discount_amount_bf)

      // Sale type
      saleType = 'BF'
      discountType = banner.discount_type_bf

      // Get code and date depending on region
      if(region !== 'en-us'){
        offerCode = banner.offer_code_bf.toUpperCase()
        offerEnds = banner.date_text_bf
        offerAmount = banner.discount_amount_bf
      } else{
        offerCode = banner.offer_code_us_bf.toUpperCase()
        offerEnds = banner.date_text_us_bf
        offerAmount = banner.discount_amount_us_bf
      }

      // Flat discount
      if(discountType === 'flatdiscount') {
        for(const price of product.prices){
          const fullCost = Math.round(price.cost * 100) / 100
          const priceDiscounted = Math.round((fullCost - offerAmount) * 100) / 100
          const discountAmount = priceDiscounted === fullCost ? 0 : fullCost - priceDiscounted
          const discountPercentage = priceDiscounted === fullCost ? 0 : Math.round(( (fullCost - priceDiscounted)/fullCost ) * 100)
          const priceObject = {
            id: price.id,
            label: price.label,
            fullCost,
            cost: priceDiscounted,
            discountAmount,
            discountPercentage,
            code:offerCode,
            ends:offerEnds,
            // discountAmount: fullCost - priceDiscounted,
            // discountPercentage: ( priceDiscounted / fullCost ) * 100
            // discountPercentage: Math.round(( (fullCost - priceDiscounted)/fullCost ) * 100),
          }
          pricesDiscounted.push(priceObject)
        }
      }

      // Percentage discount
      if(discountType === 'percentage') {
        for(const price of product.prices){
          const fullCost = Math.round(price.cost * 100) / 100
          const priceDiscounted = Math.round((fullCost * ( ( 100 - offerAmount ) / 100 )) * 100) / 100
          const discountAmount = priceDiscounted === fullCost ? 0 : fullCost - priceDiscounted
          const discountPercentage = offerAmount
          const priceObject = {
            id: price.id,
            label: price.label,
            fullCost,
            cost: priceDiscounted,
            discountAmount,
            discountPercentage,
            code:offerCode,
            ends:offerEnds,
            // discountAmount: fullCost - priceDiscounted,
            // discountPercentage: ( priceDiscounted / fullCost ) * 100
            // discountPercentage: Math.round(( (fullCost - priceDiscounted)/fullCost ) * 100),
          }
          pricesDiscounted.push(priceObject)
        }
      }

      // console.log('pricesDiscounted: ',pricesDiscounted)

    }

    // Check if this course is on sale (NON BF)
    else if (
      onSale.includes(postID) ||
      onSale.includes(postID+'b') || // BUNDLES
      onSale.includes(postID+'v') // VIRTUAL
      ) {

     // console.log('Product on sale (NON BF): ',postID,banner.discount_amount)

      // sale type
      saleType = 'STANDARD'
      discountType = banner.discount_type

      // Get code and date depending on region
      if(region !== 'en-us'){
        offerCode = banner.offer_code.toUpperCase()
        offerEnds = banner.date_text
        offerAmount = banner.discount_amount
      } else{
        offerCode = banner.offer_code_us.toUpperCase()
        offerEnds = banner.date_text_us
        offerAmount = banner.discount_amount_us
      }

      // Flat discount
      if(discountType === 'flatdiscount') {
        for(const price of product.prices){
          const fullCost = Math.round(price.cost * 100) / 100
          const priceDiscounted = Math.round((fullCost - offerAmount) * 100) / 100
          const discountAmount = priceDiscounted === fullCost ? 0 : fullCost - priceDiscounted
          const discountPercentage = priceDiscounted === fullCost ? 0 : Math.round(( (fullCost - priceDiscounted)/fullCost ) * 100)
          const priceObject = {
            id: price.id,
            label: price.label,
            fullCost,
            cost: priceDiscounted,
            discountAmount,
            discountPercentage,
            code:offerCode,
            ends:offerEnds,
            // discountAmount: fullCost - priceDiscounted,
            // discountPercentage: ( priceDiscounted / fullCost ) * 100
            // discountPercentage: Math.round(( (fullCost - priceDiscounted)/fullCost ) * 100),
          }
          pricesDiscounted.push(priceObject)
        }
      }

      // Percentage discount
      if(discountType === 'percentage') {
        for(const price of product.prices){
          const fullCost = Math.round(price.cost * 100) / 100
          const priceDiscounted = Math.round((fullCost * ( ( 100 - offerAmount ) / 100 )) * 100) / 100
          const discountAmount = priceDiscounted === fullCost ? 0 : fullCost - priceDiscounted
          const discountPercentage = offerAmount
          const priceObject = {
            id: price.id,
            label: price.label,
            fullCost,
            cost: priceDiscounted,
            discountAmount,
            discountPercentage,
            code:offerCode,
            ends:offerEnds,
           // discountAmount: fullCost - priceDiscounted,
            // discountPercentage: ( priceDiscounted / fullCost ) * 100
          }
          pricesDiscounted.push(priceObject)
        }
      }

      // console.log('pricesDiscounted: ',pricesDiscounted)

    }

    else {
      // console.log('Product not on sale: ',postID)
      priceDiscounted = cost
      pricesDiscounted = product.prices
    }

    const discountAmount = priceDiscounted === cost ? 0 : cost - priceDiscounted
    const discountPercentage = priceDiscounted === cost ? 0 : Math.round(( (cost - priceDiscounted)/cost ) * 100)

    priceData = {
      postID,
      sku: product.id,
      productName: product.name,
      productType: product.type,
      productDescription: product.description,
      priceEcommerce: cost,
      displayPrice: priceText,
      discountPrice: priceDiscounted,
      pricesDiscounted,
      originalPrice: cost,
      discountAmount,
      discountPercentage,
      discountType,
      saleType,
      offerCode,
      offerEnds,
      product,
      page: product.page
    }

    /*
    $price_data['sku'] = $sku;
    $price_data['product'] = $title;
    $price_data['type'] = $ecommerce_course_type;
    $price_data['ecommerce'] = $course_price_ecommerce;
    $price_data['output_price'] = $output_price;
    $price_data['discount_price'] = (float) $course_discount_price;
    $price_data['original_price'] = (float) $course_original_price;
    $price_data['discount_amount'] = $course_original_price - $course_discount_price;
    $price_data['discount_percentage'] = ( ($course_original_price - $course_discount_price)/$course_original_price ) * 100;
    $price_data['discount_type'] = $discount_type;
    $price_data['sale_type'] = 'none';
    $price_data['offer_code'] = '';
    $price_data['offer_ends'] = '';
    */

    // console.log('Final Price Data: ',priceData)

    return priceData
  })
  // RETURN FIELD
  Vue.filter('outputField', (data,fieldName) => {
    return data[fieldName]
  })
  // Convet Modal class
  Vue.filter('convertModal', (string) => {
    if(m[string]){
    return m[string]
    }
    return string
  })
  // Extension Name from ID
  Vue.filter('extensionFromId', (id) => {
    return extName[id]
  })
  // RETURN FIELD
  Vue.filter('formatPercent', (number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0,      
      maximumFractionDigits: 0,
   });
   // console.log(price)
    return Number(formatter.format(number))
  })

  // CURRENCIES
  Vue.filter('priceFromLabel', ( prices) => {
    const defaultCurrency = store.state.geolocation.detectedCurrency || app.$cookies.get('tefl_geolocation_currency') || 'GBP'
    const defaultPriceLabel = g[defaultCurrency]
    // const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : defaultPriceLabel
    const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : store.state.cart.defaultPriceLabel
    const price = prices.filter(price => price.label === l)
    // Add default in case regional pricing isn't set up
    const h = prices.filter(price => price.label === 'DEFAULT')
    const currency = f[l]
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    });

    if (price[0])
      return formatter.format(price[0].cost)
    else
      return formatter.format(h[0].cost)
  })
  Vue.filter('priceFromLabelNumber', (prices) => {
    const defaultCurrency = store.state.geolocation.detectedCurrency || app.$cookies.get('tefl_geolocation_currency') || 'GBP'
    const defaultPriceLabel = g[defaultCurrency]
    // const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : defaultPriceLabel
    const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : store.state.cart.defaultPriceLabel
    const price = prices.filter(price => price.label === l)
    // Add default in case regional pricing isn't set up
    const h = prices.filter(price => price.label === 'DEFAULT')
    if (price[0])
      return price[0].cost
    else
      return h[0].cost
  })
  Vue.filter('discountAmountFromLabel', (prices) => {
    const defaultCurrency = store.state.geolocation.detectedCurrency || app.$cookies.get('tefl_geolocation_currency') || 'GBP'
    const defaultPriceLabel = g[defaultCurrency]
    // const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : defaultPriceLabel
    const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : store.state.cart.defaultPriceLabel
     const price = prices.filter(price => price.label === l)
     // Add default in case regional pricing isn't set up
     const h = prices.filter(price => price.label === 'DEFAULT')
     const currency = f[l]
     const formatter = new Intl.NumberFormat('en-US', {
       style: 'currency',
       currency,
     });
 
     if (price[0])
       return formatter.format(price[0].discountAmount)
     else
       return formatter.format(h[0].discountAmount)
   })
  Vue.filter('discountPercentageFromLabel', (prices) => {
    const defaultCurrency = store.state.geolocation.detectedCurrency || app.$cookies.get('tefl_geolocation_currency') || 'GBP'
    const defaultPriceLabel = g[defaultCurrency]
    // const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : defaultPriceLabel
    const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : store.state.cart.defaultPriceLabel
     const price = prices.filter(price => price.label === l)
    // Add default in case regional pricing isn't set up
    const h = prices.filter(price => price.label === 'DEFAULT')
    if (price[0])
      return price[0].discountPercentage
    else
      return h[0].discountPercentage
  })
  Vue.filter('flagFromLabel', (label,countrycode) => {
    const country = store.state.geolocation.detectedCountry
    const region = store.state.user.userRegion === 'default' ? store.state.geolocation.detectedRegion : store.state.user.userRegion
    const regionClicked = store.state.cart.regionClicked
    const defaultCurrency = store.state.geolocation.detectedCurrency || app.$cookies.get('tefl_geolocation_currency') || 'GBP'
    const defaultPriceLabel = g[defaultCurrency]
    let l = store.state.cart.price_label !== '' ? store.state.cart.price_label : defaultPriceLabel
    const europeRegion = ['AL','AD','AM','AT','BY','BE','BA','BG','CH','CY','CZ','DE','DK','EE','ES','FO','FI','FR','GB','GE','GI','GR','HU','HR','IE','IS','IT','LI','LT','LU','LV','MC','MK','MT','NO','NL','PL','PT','RO','RS','RU','SE','SI','SK','SM','TR','UA','VA',]
    const americaRegion = ["US","AU","CA"]
    const ukRegion = ["GB"]
    const asiaRegion = ["CN"]
    const africaRegion = ["ZA"]
    if (l === 'IRELAND'){
      country === 'IE' ? l = 'IRELAND' : l = 'EU'
    }
    if (regionClicked === 'EU'){
      l = 'EU'
    }
    if (regionClicked === 'IRELAND'){
      l = 'IRELAND'
    }
    if((l === 'TEFLUS' && country !== 'US') || regionClicked === 'ROW'){
      let flag = countrycode.toUpperCase();
      if (flag === 'GB' || flag === 'AU' || flag === 'US' || flag === 'ZA' || flag === 'CA' || flag === 'IE' || europeRegion.includes(flag)){
        flag = 'globe'
        if(regionClicked === 'TEFLUS'){
          flag = 'us'
        }
      }
      return 'fi-' + flag.toLowerCase()
    }

    return 'fi-' + e[l]
  })
  Vue.filter('currencyFromLabel', (label) => {
    const defaultCurrency = store.state.geolocation.detectedCurrency || app.$cookies.get('tefl_geolocation_currency') || 'GBP'
    const defaultPriceLabel = g[defaultCurrency]
    // const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : defaultPriceLabel
    const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : store.state.cart.defaultPriceLabel
    return d[l]
  })
  Vue.filter('labelFromCurrency', (currency) => {
    const c = currency
    return g[c]
  })
  Vue.filter('currencyCodeFromLabel', (label) => {
    const defaultCurrency = store.state.geolocation.detectedCurrency || app.$cookies.get('tefl_geolocation_currency') || 'GBP'
    const defaultPriceLabel = g[defaultCurrency]
    // const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : defaultPriceLabel
    const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : store.state.cart.defaultPriceLabel
    return f[l]
  })
  // Create our number formatter.
  Vue.filter('formatAsPrice', (price) => {
    const defaultCurrency = store.state.geolocation.detectedCurrency || app.$cookies.get('tefl_geolocation_currency') || 'GBP'
    const defaultPriceLabel = g[defaultCurrency]
    // const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : defaultPriceLabel
    const l = store.state.cart.price_label !== '' ? store.state.cart.price_label : store.state.cart.defaultPriceLabel
    const currency = f[l]
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency,
    });
   // console.log(price)
    return formatter.format(price)
  })
  // Create our number formatter.
  Vue.filter('twoDecimalPlaces', (number) => {
    const formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,      
      maximumFractionDigits: 2,
   });
   // console.log(price)
    return Number(formatter.format(number))
  })

  Vue.filter('timeago', (date) => {
    const newdate = new Date(date)
    const seconds = Math.floor((new Date() - newdate) / 1000);
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
      return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return interval + " months";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return interval + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  })
  Vue.filter('cdn', (url) => {
    // console.log('CDN', url)
    // const imgRegex = /[^\s]+(.*?).(jpg|jpeg|JPG|JPEG)$/;
    const d = url.match(/^.*\/\/[^/]+/)
    // const img = url.match(imgRegex)
    let result;
    if (d)
      result = url.replace(/^.*\/\/[^/]+/, 'https://cdn-wp.tefl.org')
    else
      result = 'https://cdn-wp.tefl.org' + url
    // if (img)
      // result = result+'.webp'
    // console.log('CDN result',result)
    return result
  })
  Vue.filter('toRelative', (url) => {
    const d = url.match(/^.*\/\/[^/]+/)
    if (d)
      return url.replace(/^.*\/\/[^/]+/, '')
    else
      return url
  })
  // DATES
  Vue.filter('formatDate', (date, format) => {
    // console.log ('DATE TO CONVERT', date)
    if (date !== null && date !== ''){
      if (!date.endsWith("00")) {
        date = date + ' 23:59:59'
      }
      const isDST = moment.tz(moment(), 'Europe/London').isDST();
      // CONVERSION NOT NEEDED HERE
      // let dateTime
      /*
      if (isDST) {
        dateTime = moment(date).subtract(1, 'hours').utc().format(format)
      } else {
        dateTime = moment(date).utc().format(format)
      }
      */
      const dateTime = moment(date).utc().format(format)
      // console.log('CONVERTED DATE', dateTime)
      return dateTime
    } else {
      return null
    }
  })
  Vue.filter('niceDate', (date) => {
    let formattedDate
    // Add endOf day to Fix midnight tonight issue
    if(moment(date).endOf("day").diff(moment(), 'hours') > 24) {
      formattedDate = moment(date).format('MMMM Do')+'.'
    } else if(moment(date).endOf("day").diff(moment(), 'hours') < 24 && moment(date).endOf("day").diff(moment(), 'hours') > 0) {
      formattedDate = 'midnight tonight!'
    } else {
      formattedDate = 'Offer has ended'
    }
    return formattedDate
  })
  Vue.filter('convertDate', (date, format) => {

    const isDST = moment.tz(moment(date), 'Europe/London').isDST();
    const city = store.state.geolocation.detectedCity
    // return moment.tz.guess();
    const start = moment.tz(date, 'Europe/London')
    const tz = store.state.geolocation.detectedTimezone
    const niceTz = tz.substring(tz.indexOf('/') + 1);
    niceTz.replace(/_/g, ' ');
    let dateTime
    if (isDST) {
      dateTime = moment.tz(start, tz).subtract(1, 'hours').format(format)
    } else {
      dateTime = moment.tz(start, tz).format(format)
    }
    return '<strong>' + city + ': </strong>' + dateTime
  })
  Vue.filter('isDST', (text) => {
    const isDST = moment.tz(moment(), 'Europe/London').isDST();
    let tz = text
    if (isDST) {
      tz = 'BST'
    } else {
      tz = 'GMT'
    }
    return tz
  })
  Vue.filter('convertDateSimple', (date, format) => {

    const isDST = moment.tz(moment(date), 'Europe/London').isDST();
    const city = store.state.geolocation.detectedCity
    const start = moment.tz(date, 'Europe/London')
    const tz = store.state.geolocation.detectedTimezone
    let dateTime
    if (isDST) {
      dateTime = moment.tz(start, tz).subtract(1, 'hours').format(format)
    } else {
      dateTime = moment.tz(start, tz).format(format)
    }
    return dateTime
  })
  Vue.filter('isDST', (text) => {
    const isDST = moment.tz(moment(), 'Europe/London').isDST();
    let tz = text
    if (isDST) {
      tz = 'BST'
    } else {
      tz = 'GMT'
    }
    return tz
  })
  // Find urls and make them clickable links
  Vue.filter('replaceURLs', (message) => {
    if (!message) return;

    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    const emailRegex = /(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/g
    message = message.replace(urlRegex, function (url) {
      let hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
    message = message.replace(emailRegex, function (url) {

      return '<a href="mailto:' + url + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    })
    return message
  })
  // MISC
  Vue.filter('extensionName', (name) => {
    return name.slice(19, -13)
  })
  Vue.filter('fixtabname', (name, isVirtual, linebreak) => {
    if (isVirtual && linebreak)
      return name.replace(/(\d{1,3}-hour)/g, '$1<br />Virtual')
    else if (isVirtual)
      return name.replace(/(\d{1,3}-hour)/g, '$1 Virtual')
    else
      return name.replace(/(\d{1,3}-hour)/g, '$1')
  })
  Vue.filter('capitalise', (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  })
  Vue.filter('courseFeatures', (region, hours, type) => {
    hours = parseInt(hours)
    let featureArray;
    // standard
    if (region === 'us') {
      featureArray = [
        'The TEFL Org’s internationally recognized BAC, SQA and ODLQC center accreditation',
        'Internationally recognised TEFL certificate issued by The TEFL Org',
        'Lifetime TEFL Resume support',
        'Lifetime access to our TEFL Jobs Center',
        'Downloadable TEFL coursebook'
      ]
    } else {
      featureArray = [
        'The TEFL Org’s internationally recognised BAC, SQA and ODLQC centre accreditation',
        'Internationally recognised TEFL certificate issued by The TEFL Org',
        'Lifetime TEFL CV support',
        'Lifetime access to our TEFL Jobs Centre',
        'Downloadable TEFL coursebook'
      ]
    }

    // type
    if (type === 'online course') {
      featureArray.push('Personal online TEFL tutor')
      // hours
      switch (true) {
        case hours === 200:
          featureArray.push('7 months to complete your online work')
          featureArray.splice(1, 0, 'Recognised by the US & English governments: DEAC AQC approved and Ofqual regulated');
          break;
        case hours === 168:
          featureArray.push('7 months to complete your online work')
          featureArray.splice(1, 0, 'Recognised by the US & English governments: DEAC AQC approved and Ofqual regulated');
          break;
        case hours === 120:
          featureArray.push('6 months to complete your online work')
          featureArray.splice(1, 0, 'Recognised by the US government: DEAC AQC approved');
          break;
        case hours === 100:
          featureArray.push('5 months to complete your online work');
          break;
        case hours === 80:
          featureArray.push('4 months to complete your online work');
          break;
        case hours === 50:
          featureArray.push('3 months to complete your online work');
          break;
      }
    } else {
      switch (true) {
        case hours > 190:
          featureArray.push('Personal online TEFL tutor', '7 months to complete your online work');
          featureArray.splice(1, 0, 'Recognised by the US & English governments: DEAC AQC approved and Ofqual regulated');
          break;
        case hours <= 190 && hours > 160:
          featureArray.push('Personal online TEFL tutor', '7 months to complete your online work');
          featureArray.splice(1, 0, 'Recognised by the US & English governments: DEAC AQC approved and Ofqual regulated');
          break;
        case hours <= 150 && hours > 140:
          featureArray.push('Personal online TEFL tutor', '6 months to complete your online work', 'Recognised by the US government: DEAC AQC approved');
          break;
        case hours <= 140 && hours > 130:
          featureArray.push('Personal online TEFL tutor', '6 months to complete your online work', 'Recognised by the US government: DEAC AQC approved');
          break;
        case hours === 130:
          featureArray.push('Personal online TEFL tutor', '5 months to complete your online work', 'Recognised by the US government: DEAC AQC approved');
          break;
        case hours < 130 && hours > 60:
          featureArray.push('Personal online TEFL tutor', '5 months to complete your online work', 'Recognised by the US government: DEAC AQC approved');
          break;


      }
    }
    return featureArray
  })
  Vue.filter('courseModules', (virtual, hours, type) => {
    hours = parseInt(hours)
    const moduleArray = []
    // standard

    // type
    if (type === 'online course') {

      // hours
      switch (true) {
        case hours === 200:
          moduleArray.push('<strong>Level 5:</strong> 100 hours - TEFL Methodology', '<strong>Level 5:</strong> 80 hours - English Language and Grammar', '10 hours - Telephone Teaching', '10 hours - Teaching Large Classes')
          break;
        case hours === 168:
          moduleArray.push('<strong>Level 5:</strong> 100 hours - TEFL Methodology', '<strong>Level 5:</strong> 80 hours - English Language and Grammar', '10 hours - Telephone Teaching', '10 hours - Teaching Large Classes')
          break;
        case hours === 120:
          moduleArray.push('50 hours - TEFL Methodology', '30 hours - English Language and Grammar', '20 hours - Video Observation', '10 hours - Telephone Teaching', '10 hours - Teaching Large Classes');
          break;
        case hours === 100:
          moduleArray.push('50 hours - TEFL Methodology', '30 hours - English Language and Grammar', '20 hours - Video Observation');
          break;
        case hours === 80:
          moduleArray.push('50 hours - TEFL Methodology', '30 hours - English Language and Grammar');
          break;
        case hours === 50:
          moduleArray.push('50 hours - TEFL Methodology');
          break;
      }
    } else {
      switch (true) {
        case hours > 229:
        if (!virtual) {
          moduleArray.push('30 hour practical classroom TEFL course');
        } else {
          moduleArray.push('30 hour practical <strong>virtual</strong> classroom TEFL course held over Zoom');
        }
        moduleArray.push('<strong>Level 5:</strong> 100 hours - TEFL Methodology', '<strong>Level 5:</strong> 80 hours - English Language and Grammar', '10 hours - Telephone Teaching', '10 hours - Teaching Large Classes');
        break;
        case hours <= 229 && hours > 200:

        if (!virtual) {
          moduleArray.push('20 hour practical classroom TEFL course');
        } else {
          moduleArray.push('20 hour practical <strong>virtual</strong> classroom TEFL course held over Zoom');
        }
        moduleArray.push('<strong>Level 5:</strong> 100 hours - TEFL Methodology', '<strong>Level 5:</strong> 80 hours - English Language and Grammar', '10 hours - Telephone Teaching', '10 hours - Teaching Large Classes');
        break;
        case hours <= 200 && hours > 190:

          if (!virtual) {
            moduleArray.push('30 hour practical classroom TEFL course');
          } else {
            moduleArray.push('30 hour practical <strong>virtual</strong> classroom TEFL course held over Zoom');
          }
          moduleArray.push('<strong>Level 5:</strong> 79 hours - TEFL Methodology', '30 hours - English Language and Grammar', '20 hours - Video Observation', '<strong>Level 5:</strong> 19-hours - The Principles of Teaching English as a Foreign Language Course', '10 hours - Telephone Teaching', '10 hours - Teaching Large Classes');
          break;
        case hours <= 190 && hours > 160:
          if (!virtual) {
            moduleArray.push('20 hour practical classroom TEFL course');
          } else {
            moduleArray.push('20 hour practical <strong>virtual</strong> classroom TEFL course held over Zoom');
          }
          moduleArray.push('<strong>Level 5:</strong> 79 hours - TEFL Methodology', '30 hours - English Language and Grammar', '20 hours - Video Observation', '<strong>Level 5:</strong> 19-hours - The Principles of Teaching English as a Foreign Language Course', '10 hours - Telephone Teaching', '10 hours - Teaching Large Classes');
          break;
        case hours <= 150 && hours > 140:
          if (!virtual) {
            moduleArray.push('30 hour practical classroom TEFL course');
          } else {
            moduleArray.push('30 hour practical <strong>virtual</strong> classroom TEFL course held over Zoom');
          }
          moduleArray.push('50 hours - TEFL Methodology', '30 hours - English Language and Grammar', '20 hours - Video Observation', '10 hours - Telephone Teaching', '10 hours - Teaching Large Classes');
          break;
        case hours <= 140 && hours > 130:
          if (!virtual) {
            moduleArray.push('20 hour practical classroom TEFL course');
          } else {
            moduleArray.push('20 hour practical <strong>virtual</strong> classroom TEFL course held over Zoom');
          }
          moduleArray.push('50 hours - TEFL Methodology', '30 hours - English Language and Grammar', '20 hours - Video Observation', '10 hours - Telephone Teaching', '10 hours - Teaching Large Classes');

          break;
        case hours === 130:
          if (!virtual) {
            moduleArray.push('30 hour practical classroom TEFL course');
          } else {
            moduleArray.push('30 hour practical <strong>virtual</strong> classroom TEFL course held over Zoom');
          }
          moduleArray.push('50 hours - TEFL Methodology', '30 hours - English Language and Grammar', '20 hours - Video Observation');
          break;
        case hours < 130 && hours > 60:
          if (!virtual) {
            moduleArray.push('20 hour practical classroom TEFL course');
          } else {
            moduleArray.push('20 hour practical <strong>virtual</strong> classroom TEFL course held over Zoom');
          }
          moduleArray.push('50 hours - TEFL Methodology', '30 hours - English Language and Grammar', '20 hours - Video Observation');
          break;
        case hours <= 60 && hours > 30:
          if (!virtual) {
            moduleArray.push('30 hour practical classroom TEFL course');
          } else {
            moduleArray.push('30 hour practical <strong>virtual</strong> classroom TEFL course held over Zoom');
          }
          moduleArray.push('30 hours - English Language and Grammar');
          break;
        case hours <= 30 && hours > 20:
          if (!virtual) {
            moduleArray.push('30 hour practical classroom TEFL course');
          } else {
            moduleArray.push('30 hour practical <strong>virtual</strong> classroom TEFL course held over Zoom');
          }
          break;
        case hours <= 20 && hours > 10:
          if (!virtual) {
            moduleArray.push('20 hour practical classroom TEFL course');
          } else {
            moduleArray.push('20 hour practical <strong>virtual</strong> classroom TEFL course held over Zoom');
          }
          break;
        case hours <= 10:

          moduleArray.push('Online classroom TEFL course');

          break;

      }
    }
    return moduleArray
  })
  Vue.filter('classAvailability', (value) => {
    let spaces_text;
    switch (true) {
      case value <= 0:
        spaces_text = 'SOLD OUT';
        break;
      case value === 1:
        spaces_text = '1 place remaining';
        break;

      case value <= 4:
        spaces_text = value + ' places remaining';
        break;

      case value <= 8:
        spaces_text = 'Limited availability';
        break;

      default:
        spaces_text = 'Good availability';
        break;

    }

    return spaces_text
  })
 
  Vue.filter('isBST',(date) => {
    function lastSunday(month, year) {
      const dt = new Date()
      const lastDayOfMonth = new Date(
        Date.UTC(year || dt.getFullYear(), month + 1, 0)
      )
      const day = lastDayOfMonth.getDay()
      return new Date(
        Date.UTC(
          lastDayOfMonth.getFullYear(),
          lastDayOfMonth.getMonth(),
          lastDayOfMonth.getDate() - day
        )
      )
    }
    const d = date || new Date();
    const starts = lastSunday(2, d.getFullYear())
    starts.setHours(1)
    const ends = lastSunday(9, d.getFullYear())
    ends.setHours(1)
    return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime()
  })
}
Vue.filter('localTimezone', (timezone) => {
  const tz = moment.tz(moment(), timezone).format('z');
  return tz
})
Vue.filter('timeAgo', (date) => {
  const timeAgo = moment(date).fromNow();
  return timeAgo
})
Vue.filter('zerofill', function (value) {
  // returns a single number with added 0 i.e 9 becomes 09
  return ( value < 10 && value > -1 ? '0' : '' ) + value;
});
Vue.filter('truncateString',function (value, num) {
  value = value.charAt(0).toUpperCase() + value.slice(1);
  if (value.length <= num) {
    return value
  }
  return value.slice(0, num) + '...'
})
Vue.filter('noStudents',(number) => {
  // console.log('noStudents filter', number)
  return optionsData.number_students
})
Vue.filter('roundScore',(number) => {
  return Math.round(number * 10) / 10
})